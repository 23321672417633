@import 'src/utils/utils';

.sided {
  @include zero;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 40px;

  @media(min-width: $lg) {
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    padding-top: 30px;
    padding-right: 30px;
    padding-bottom: 100px;
    padding-left: 30px;
    column-gap: 30px;
  }
  @media(min-width: $xxl) {
    column-gap: 100px;
  }

  &__content {
    // display: flex;
    // flex-direction: column;
    flex-grow: 1;

    & > section:last-child,
    & > div:last-child {
      margin-bottom: 0;
    }

    & > h2 {
      @include zero;
      @include Head-35-bold;
      margin-top: 25px;
      margin-right: 16px;
      margin-bottom: 20px;
      margin-left: 16px;
      color: $Main-1;
    
      @media(min-width: $lg) {
        margin-right: 0;
        margin-left: 0;
      }
    
      @media(min-width: $xxl) {
        @include Head-45-bold;
        @include box;
        margin-top: 50px;
        margin-bottom: 50px;
      }
    }
  }

  &__aside {

    .menu {
      @media(min-width: $lg) {
        position: sticky;
        top: 30px;
      }
    }
  }
}